import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import "./Educator.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  educatorEmailIds,
  educatorApproveChange,
  educatorDelete,
} from "../redux/Educators";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../redux/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
// import { educatorRegisterCb } from "../../redux/user";
import { educatorRegisterCb } from "../redux/user";
import { categoryList } from "../redux/courses";
import { AiFillCamera } from "react-icons/ai";
import Profimage from "../../assets/images/userimage.png";

const Educators = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [educatorData, setEducatorData] = useState([]);
  const [dummydataComplete, setdummydataComplete] = useState([]);
  const [refresher, setRefresher] = useState(true);
  const [selectedEducators, setSelectedEducators] = useState([]);
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(0);
  const [showAddEducator, setShowAddEducator] = useState(false);
  const [categoryListData, setCategoryListData] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [file, setFile] = useState("");

  const {
    register: registerEducator,
    handleSubmit: handleSubmitEducator,
    formState: { errors: educatorErrors },
    reset: resetEducator,
  } = useForm({ mode: "onBlur" });

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = educatorData.slice(indexOfFirstItem, indexOfLastItem);

  const handleSendMail = () => {
    const selectedEducatorIds = selectedEducators;
    sessionStorage.setItem(
      "selectedEducatorIds",
      selectedEducatorIds.join(",")
    );
    navigate("/send_mail_edu");
  };

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      educatorEmailIds((resp) => {
        if (resp.status) {
          setEducatorData(resp.data);
          setdummydataComplete(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  }, [refresher]);
  const educatorView = (id) => {
    sessionStorage.setItem("EducatorId", id);
    navigate("/view_educator");
  };

  const educatorApprove = (id) => {
    dispatch(loader(true));
    let data = {};
    data["eduId"] = id;
    dispatch(
      educatorApproveChange(data, (resp) => {
        if (resp.status) {
          setRefresher(!refresher);
          toast.success(resp.message);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const handleEducatorDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this educator?")) {
      dispatch(loader(true));
      let data = {
        eduId: id,
      };

      dispatch(
        educatorDelete(data, (resp) => {
          if (resp.status) {
            setRefresher(!refresher);
            toast.success(resp.message);
            dispatch(loader(false));
          } else {
            dispatch(loader(false));
            toast.error(resp.message);
          }
        })
      );
    }
  };

  const handleCheckboxChange = (educatorId) => {
    setSelectedEducators((prevSelected) => {
      if (prevSelected.includes(educatorId)) {
        // If educatorId is already selected, remove it
        return prevSelected.filter((id) => id !== educatorId);
      } else {
        // If educatorId is not selected, add it
        return [...prevSelected, educatorId];
      }
    });
  };

  const filterBySearch = (event) => {
    const query = event.target.value;
    var updatedlist = [...dummydataComplete];

    var updatedList = updatedlist.filter((item) => {
      return item.email.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });

    setEducatorData(updatedList);
  };

  const handleAddEducatorShow = () => setShowAddEducator(true);
  const handleAddEducatorClose = () => {
    setShowAddEducator(false);
    resetEducator();
    setPreviewImage("");
    setFile("");
  };

  const onSubmitEducator = (data) => {
    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("city", data.city);
    formData.append("expertise", data.expertise);
    formData.append("designation", data.designation);

    // Only append if files/data exist
    if (data.cvUrl?.[0]) formData.append("cvUrl", data.cvUrl[0]);
    if (data.course1Url?.[0]) formData.append("course1Url", data.course1Url[0]);
    if (data.course2Url?.[0]) formData.append("course2Url", data.course2Url[0]);
    if (data.description) formData.append("description", data.description);
    if (file) formData.append("photoUrl", file);

    dispatch(loader(true));
    dispatch(
      educatorRegisterCb(formData, (result) => {
        dispatch(loader(false));
        if (result.status) {
          toast.success(result.message);
          handleAddEducatorClose();
        } else {
          toast.error(result.message);
        }
      })
    );
  };

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      categoryList((resp) => {
        if (resp.status) {
          setCategoryListData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [dispatch]);

  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreviewImage(
        "https://cdn.landesa.org/wp-content/uploads/default-user-image.png"
      );
      setFile(null);
    }
  };

  return (
    <>
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3>Educators List</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="">Educators</Link>
                </p>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <div className="col-12">
                  <div className="searchBtNs">
                    <div className="row">
                      <div className="col-xl-8 col-lg-8 col-md-6 pt-2">
                        <div className="BtNSSearcH">
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Search By Email Address"
                              aria-describedby="basic-addon2"
                              onChange={filterBySearch}
                            />
                            <Button
                              variant="outline-secondary"
                              id="button-addon2"
                              className="btnSrch"
                            >
                              <BsSearch />
                            </Button>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 mt-2 pt-2">
                        <div className="d-flex gap-2">
                          <div className="BtNSSearcH">
                            <Link
                              to="/send_mail_edu"
                              className="Grn-Btn"
                              onClick={handleSendMail}
                            >
                              Send Mail
                            </Link>
                          </div>
                          <div className="BtNSSearcH">
                            <button
                              onClick={handleAddEducatorShow}
                              className="Grn-Btn"
                            >
                              Add Educator
                            </button>
                          </div>
                          <Modal
                            show={showAddEducator}
                            onHide={handleAddEducatorClose}
                            size="lg"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Add Educator</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form
                                onSubmit={handleSubmitEducator(
                                  onSubmitEducator
                                )}
                              >
                                <Form.Group className="mb-3 text-center">
                                  <div className="profile-pic-wrapper">
                                    <img
                                      src={previewImage || Profimage}
                                      alt="Profile"
                                      className="profile-pic"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                    <label className="upload-label">
                                      <AiFillCamera className="camera-icon" />
                                      <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleProfilePic}
                                        style={{ display: "none" }}
                                      />
                                    </label>
                                  </div>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter name"
                                    {...registerEducator("name", {
                                      required: "Name is required",
                                      pattern: {
                                        value: /^[A-Za-z\s]+$/,
                                        message:
                                          "Name should only contain letters",
                                      },
                                    })}
                                  />
                                  {educatorErrors.name && (
                                    <small
                                      style={{ color: "red", float: "left" }}
                                    >
                                      {educatorErrors.name.message}
                                    </small>
                                  )}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>Email Address</Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    {...registerEducator("email", {
                                      required: "Email is required",
                                      pattern: {
                                        value:
                                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
                                        message: "Invalid email format",
                                      },
                                    })}
                                  />
                                  {educatorErrors.email && (
                                    <small
                                      style={{ color: "red", float: "left" }}
                                    >
                                      {educatorErrors.email.message}
                                    </small>
                                  )}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>Phone Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter phone number"
                                    {...registerEducator("phoneNumber", {
                                      required: "Phone number is required",
                                      pattern: {
                                        value:
                                          /^(?![0]{10}$)(?:\(\d{3}\)\s?|\d{3}[-.\s]?)?\d{3}[-.\s]?\d{4}$/,
                                        message:
                                          "Please enter a valid 10-digit phone number",
                                      },
                                    })}
                                  />
                                  {educatorErrors.phoneNumber && (
                                    <small
                                      style={{ color: "red", float: "left" }}
                                    >
                                      {educatorErrors.phoneNumber.message}
                                    </small>
                                  )}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>City</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter city"
                                    {...registerEducator("city", {
                                      required: "City is required",
                                      pattern: {
                                        value: /^[A-Za-z\s]+$/,
                                        message:
                                          "City should only contain letters",
                                      },
                                    })}
                                  />
                                  {educatorErrors.city && (
                                    <small
                                      style={{ color: "red", float: "left" }}
                                    >
                                      {educatorErrors.city.message}
                                    </small>
                                  )}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>Designation</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter designation"
                                    {...registerEducator("designation", {
                                      required: "Designation is required",
                                      pattern: {
                                        value: /^[A-Za-z\s]+$/,
                                        message:
                                          "Designation should only contain letters",
                                      },
                                    })}
                                  />
                                  {educatorErrors.designation && (
                                    <small
                                      style={{ color: "red", float: "left" }}
                                    >
                                      {educatorErrors.designation.message}
                                    </small>
                                  )}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>Expertise</Form.Label>
                                  <Form.Select
                                    {...registerEducator("expertise", {
                                      required: "Expertise is required",
                                      validate: (value) =>
                                        value !== "test" ||
                                        "Please select an expertise",
                                    })}
                                  >
                                    <option value="test">Select option</option>
                                    {categoryListData.map((item) => (
                                      <option key={item.id} value={item.name}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {educatorErrors.expertise && (
                                    <small
                                      style={{ color: "red", float: "left" }}
                                    >
                                      {educatorErrors.expertise.message}
                                    </small>
                                  )}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>
                                    Upload CV (PDF only) (Optional)
                                  </Form.Label>
                                  <Form.Control
                                    type="file"
                                    accept=".pdf"
                                    {...registerEducator("cvUrl", {
                                      required: false,
                                      validate: {
                                        isPDF: (file) => {
                                          if (!file || !file[0]) return true;
                                          const allowedFormats = [
                                            "application/pdf",
                                          ];
                                          return (
                                            allowedFormats.includes(
                                              file[0]?.type
                                            ) ||
                                            "Invalid file format. Please upload a PDF file."
                                          );
                                        },
                                        lessThan10MB: (files) => {
                                          if (!files || !files[0]) return true;
                                          return (
                                            files[0]?.size < 10485760 ||
                                            "File size should be Max 10MB"
                                          );
                                        },
                                      },
                                    })}
                                  />
                                  {educatorErrors.cvUrl && (
                                    <small
                                      style={{ color: "red", float: "left" }}
                                    >
                                      {educatorErrors.cvUrl.message}
                                    </small>
                                  )}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>
                                    Upload Sample Course 1 (MP4 format only)
                                    (Optional)
                                  </Form.Label>
                                  <Form.Control
                                    type="file"
                                    accept="video/mp4,video/webm"
                                    {...registerEducator("course1Url", {
                                      required: false,
                                      validate: {
                                        isVideo: (file) => {
                                          if (!file || !file[0]) return true;
                                          const allowedFormats = [
                                            "video/mp4",
                                            "video/webm",
                                          ];
                                          return (
                                            allowedFormats.includes(
                                              file[0]?.type
                                            ) ||
                                            "Invalid file format. Please upload a video file."
                                          );
                                        },
                                        lessThan50MB: (files) => {
                                          if (!files || !files[0]) return true;
                                          return (
                                            files[0]?.size < 52428800 ||
                                            "File size should be Max 50MB"
                                          );
                                        },
                                      },
                                    })}
                                  />
                                  {educatorErrors.course1Url && (
                                    <small
                                      style={{ color: "red", float: "left" }}
                                    >
                                      {educatorErrors.course1Url.message}
                                    </small>
                                  )}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>
                                    Upload Sample Course 2 (MP4 format only)
                                    (Optional)
                                  </Form.Label>
                                  <Form.Control
                                    type="file"
                                    accept="video/mp4,video/webm"
                                    {...registerEducator("course2Url", {
                                      required: false,
                                      validate: {
                                        isVideo: (file) => {
                                          if (!file || !file[0]) return true;
                                          const allowedFormats = [
                                            "video/mp4",
                                            "video/webm",
                                          ];
                                          return (
                                            allowedFormats.includes(
                                              file[0]?.type
                                            ) ||
                                            "Invalid file format. Please upload a video file."
                                          );
                                        },
                                        lessThan50MB: (files) => {
                                          if (!files || !files[0]) return true;
                                          return (
                                            files[0]?.size < 52428800 ||
                                            "File size should be Max 50MB"
                                          );
                                        },
                                      },
                                    })}
                                  />
                                  {educatorErrors.course2Url && (
                                    <small
                                      style={{ color: "red", float: "left" }}
                                    >
                                      {educatorErrors.course2Url.message}
                                    </small>
                                  )}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>
                                    Short Description (Optional)
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter description"
                                    {...registerEducator("description", {
                                      required: false,
                                      pattern: {
                                        value: /.*$/,
                                        message:
                                          "Invalid format. Description should contain only letters, numbers, and spaces",
                                      },
                                    })}
                                  />
                                  {educatorErrors.description && (
                                    <small
                                      style={{ color: "red", float: "left" }}
                                    >
                                      {educatorErrors.description.message}
                                    </small>
                                  )}
                                </Form.Group>

                                <div className="d-flex justify-content-center mt-4">
                                  <button
                                    className="Grn-Btn Half"
                                    type="submit"
                                  >
                                    Add
                                  </button>
                                  <button
                                    className="WHt-Btn Half"
                                    type="button"
                                    onClick={handleAddEducatorClose}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </Form>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Table striped hover>
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Mobile Number</th>
                      <th>Verification</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => (
                      <tr>
                        <td>
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange(item._id)}
                            checked={selectedEducators.includes(item._id)}
                          />
                        </td>
                        <td>{indexOfFirstItem + index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.phoneNumber}</td>
                        <td>
                          <button
                            className="GrnActn"
                            onClick={() => educatorApprove(item._id)}
                            style={{
                              background:
                                item.isVerified === true
                                  ? "#C7F7DA"
                                  : "#F8D9D9",
                              border:
                                item.isVerified === true
                                  ? "1px solid #107B38"
                                  : "1px solid #AB0A0A",
                            }}
                          >
                            {item.isVerified === true ? "Approved" : "Pending"}
                          </button>
                        </td>
                        <td>
                          <button
                            className="GrnActn"
                            style={{
                              background:
                                item.status?.toLowerCase() === "active"
                                  ? "#C7F7DA"
                                  : item.status?.toLowerCase() === "inactive"
                                  ? "#F8D9D9"
                                  : item.status?.toLowerCase() === "rejected"
                                  ? "#DCDAE3"
                                  : "",
                              border:
                                item.status?.toLowerCase() === "active"
                                  ? "1px solid #107B38"
                                  : item.status?.toLowerCase() === "inactive"
                                  ? "1px solid #AB0A0A"
                                  : item.status?.toLowerCase() === "rejected"
                                  ? "1px solid #6F6B80"
                                  : "",
                            }}
                          >
                            {item.status?.toLowerCase() === "active"
                              ? "Active"
                              : item.status?.toLowerCase() === "inactive"
                              ? "InActive"
                              : "Rejected"}
                          </button>
                        </td>
                        <td>
                          <div className="d-flex">
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list">
                                <li onClick={() => educatorView(item._id)}>
                                  View
                                </li>
                                <li
                                  onClick={() => handleEducatorDelete(item._id)}
                                >
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(educatorData.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        subContainerClassName={"pages pagination"}
        containerClassName={"pagination-containers  green-pagination"}
        activeClassName={"active-page"}
        forcePage={currentPage}
      />
    </>
  );
};
export default Educators;
