import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Testimonial.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import InputGroup from "react-bootstrap/InputGroup";
import { useForm, Controller } from "react-hook-form";

import { BsThreeDotsVertical } from "react-icons/bs";
import Topbar from "../../components/topbar/Topbar";
import SideBar from "../../components/Sidebar/SideBar";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../redux/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  viewTestimonialsList,
  addTestimonialCb,
  deleteTestCb,
  editTestCb,
} from "../redux/shorts";
import * as yup from "yup";
import helper from "../../helper/axiosHelper";

const Testimonial = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showview, setShowview] = useState(false);

  const [refresher, setRefresher] = useState(false);

  const [editTestData, setEditTestData] = useState();
  const [editTestViewData, setEditTestViewData] = useState();
  const [listTestimonials, setListTestimonials] = useState([]);
  const [checked, setChecked] = useState([]);

  const [isCorporate, setIsCorporate] = useState(false);
  const [corporates, setCorporates] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState("");
  const [testimonialFor, setTestimonialFor] = useState("");

  useEffect(() => {
    const fetchCorporates = async () => {
      try {
        const response = await helper.postData(
          helper.baseUrl() + "getCorporates"
        );
        setCorporates(response?.data.corporates);
      } catch (error) {
        console.error("Error fetching corporates:", error);
      }
    };
    fetchCorporates();
  }, []);

  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShowview(false);
  const handleShow1 = (item) => {
    setValue("name", item.name);
    setValue("qualification", item.qualification);
    setValue("feedback", item.feedback);
    setEditTestData(item);
    setTestimonialFor(item.testimonialFor);
    setSelectedCorporate(item.corporate || "");
    setIsCorporate(item.testimonialFor === "Corporate");
    setShowview(true);
  };

  const {
    register: register,
    control,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  const {
    register: register1,
    control1,
    formState: { errors: errors1 },
    reset: reset1,
    handleSubmit: handleSubmit1,
    setValue: setValue,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    let editTestId = sessionStorage.getItem("EditTestId");
    console.log("editTestIDsessionStorage", sessionStorage);
    dispatch(loader(true));
    dispatch(
      viewTestimonialsList((resp) => {
        dispatch(loader(false));
        if (resp.status) {
          setListTestimonials(resp.data);
          var arr = [];
          for (var i = 0; i < resp.data.length; i++) {
            arr.push(resp.data[i].isActive);
          }
          setChecked(arr);
        }
      })
    );
  }, [refresher]);

  const changeStatus = (id, index) => {
    let data = {};
    let value = {
      isHidden: !checked[index],
    };
    data["_id"] = id;
    data["isActive"] = value.isHidden;
    dispatch(loader(true));
    dispatch(
      editTestCb(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };

  const testAddFn = (data) => {
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("qualification", data.qualification);
    formData.append("feedback", data.feedback);
    formData.append("testimonialFor", testimonialFor);

    if (testimonialFor === "Corporate") {
      if (!selectedCorporate) {
        toast.error("Please select a corporate");
        return;
      }
      formData.append("corporate", selectedCorporate);
    }

    dispatch(loader(true));
    dispatch(
      addTestimonialCb(formData, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          dispatch(loader(false));
          reset();
          handleClose();
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  function shallowEqual(object1, object2) {
    if (
      object1 === null ||
      object2 === null ||
      typeof object1 !== "object" ||
      typeof object2 !== "object"
    ) {
      return {}; // Return an empty object to indicate no differences
    }

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    var arr = {};

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        arr[key] = object1[key];
      }
    }

    return arr;
  }

  const editTestFn = (data) => {
    let editTestId = editTestData._id;

    console.log("Edit Data:", {
      _id: editTestId,
      name: data.name,
      qualification: data.qualification,
      feedback: data.feedback,
      testimonialFor: testimonialFor,
      corporate: selectedCorporate,
      isActive: true,
    });

    let formData = new FormData();
    formData.append("_id", editTestId);
    formData.append("name", data.name);
    formData.append("qualification", data.qualification);
    formData.append("feedback", data.feedback);
    formData.append("testimonialFor", testimonialFor);
    formData.append("isActive", true);

    if (testimonialFor === "Corporate") {
      if (!selectedCorporate) {
        toast.error("Please select a corporate");
        return;
      }
      formData.append("corporate", selectedCorporate);
    }

    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    dispatch(loader(true));
    dispatch(
      editTestCb(formData, (resp) => {
        if (resp.status) {
          setListTestimonials(resp.data);
          dispatch(loader(false));
          toast.success(resp.message);
          setShowview(false);
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const deleteTestFn = (id) => {
    let data = {};
    data["_id"] = id;
    dispatch(loader(true));
    dispatch(
      deleteTestCb(data, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          setRefresher(!refresher);
          toast.success(resp.message);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    qualification: yup.string().required("Qualification is required"),
    feedback: yup.string().required("Feedback is required"),
    testimonialFor: yup.string().required("Please select testimonial for"),
  });

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-5">
              <h3>Testimonial List</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/testimonials">Testimonial</Link>
              </p>
            </div>
            <div className="col-6 col-md-7">
              <div className="Grn-Btnmanin">
                <button className="Grn-Btn" onClick={handleShow}>
                  add testimonial
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Qualification</th>
                    <th>Feedback</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {listTestimonials.map((item, i) => (
                    <tr key={item._id}>
                      <td>{i + 1}</td>
                      <td>{item?.name}</td>
                      <td>{item?.qualification}</td>
                      <td className="DivWidth">{item?.feedback}</td>
                      <td>
                        <div className="d-flex">
                          <div className="">
                            <div className="OnOfF">
                              <Form>
                                <Form.Check // prettier-ignore
                                  type="switch"
                                  id="custom-switch"
                                  onChange={() => changeStatus(item._id, i)}
                                  checked={checked[i]}
                                />
                              </Form>
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list tstLi">
                                <li
                                  onClick={() => {
                                    handleShow1(item);
                                  }}
                                >
                                  Edit
                                </li>
                                <li onClick={() => deleteTestFn(item._id, i)}>
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Testimonial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(testAddFn)}>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    {...register("name", {
                      required: "Name is required",
                    })}
                  />
                  {errors.name && (
                    <small style={{ color: "red" }}>
                      {errors.name.message}
                    </small>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-6 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Qualification</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    {...register("qualification", {
                      required: "qualification is required",
                    })}
                  />
                  {errors.qualification && (
                    <small style={{ color: "red" }}>
                      {errors.qualification.message}
                    </small>
                  )}
                </Form.Group>
              </div>
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Feedback</Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                style={{ height: "150px" }}
                {...register("feedback", {
                  required: "feedback is required",
                })}
              />
              {errors.feedback && (
                <small style={{ color: "red" }}>
                  {errors.feedback.message}
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Testimonial For</Form.Label>
              <div className="row bannerRadios">
                <div className="col-lg-3 col-md-4">
                  <input
                    className="bannerinput"
                    type="radio"
                    name="testimonialFor"
                    value="Student"
                    onChange={(e) => {
                      setIsCorporate(false);
                      setTestimonialFor(e.target.value);
                    }}
                  />
                  <label className="professionbanner">Student</label>
                </div>
                <div className="col-lg-3 col-md-4">
                  <input
                    className="bannerinput"
                    type="radio"
                    name="testimonialFor"
                    value="workingProfessional"
                    onChange={(e) => {
                      setIsCorporate(false);
                      setTestimonialFor(e.target.value);
                    }}
                  />
                  <label className="professionbanner">Working</label>
                </div>
                <div className="col-lg-3 col-md-4">
                  <input
                    className="bannerinput"
                    type="radio"
                    name="testimonialFor"
                    value="Doctor"
                    onChange={(e) => {
                      setIsCorporate(false);
                      setTestimonialFor(e.target.value);
                    }}
                  />
                  <label className="professionbanner">Doctor</label>
                </div>
                <div className="col-lg-3 col-md-4">
                  <input
                    className="bannerinput"
                    type="radio"
                    name="testimonialFor"
                    value="Corporate"
                    onChange={(e) => {
                      setIsCorporate(true);
                      setTestimonialFor(e.target.value);
                    }}
                  />
                  <label className="professionbanner">Corporate</label>
                </div>
                {!testimonialFor && (
                  <small style={{ color: "red", paddingLeft: "15px" }}>
                    Please select testimonial for
                  </small>
                )}
              </div>

              {isCorporate && (
                <div className="row mt-3">
                  <div className="col-lg-12">
                    <select
                      value={selectedCorporate}
                      onChange={(e) => setSelectedCorporate(e.target.value)}
                      style={{
                        height: "50px",
                        width: "100%",
                        padding: "10px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                        fontSize: "16px",
                        color: "#333",
                        outline: "none",
                      }}
                    >
                      <option value="">Select Corporate</option>
                      {corporates.map((corporate, index) => (
                        <option key={index} value={corporate._id}>
                          {corporate.corporateName}
                        </option>
                      ))}
                    </select>
                    {isCorporate && !selectedCorporate && (
                      <small style={{ color: "red" }}>
                        Please select a corporate
                      </small>
                    )}
                  </div>
                </div>
              )}
            </Form.Group>

            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* view modal */}
      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Testimonial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit1(editTestFn)}>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="TestimonialName"
                    type="text"
                    {...register1("name")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Qualification</Form.Label>
                  <Form.Control
                    type="text"
                    name="TestimonialQualification"
                    {...register1("qualification")}
                  />
                </Form.Group>
              </div>
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Feedback</Form.Label>
              <Form.Control
                as="textarea"
                name="TestimonialFeedback"
                {...register1("feedback")}
                style={{ height: "150px" }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Testimonial For</Form.Label>
              <div className="row bannerRadios">
                <div className="col-lg-3 col-md-4">
                  <input
                    className="bannerinput"
                    type="radio"
                    name="testimonialFor"
                    value="Student"
                    checked={testimonialFor === "Student"}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setIsCorporate(false);
                      setTestimonialFor(e.target.value);
                    }}
                  />
                  <label className="professionbanner">Student</label>
                </div>
                <div className="col-lg-3 col-md-4">
                  <input
                    className="bannerinput"
                    type="radio"
                    name="testimonialFor"
                    value="workingProfessional"
                    checked={testimonialFor === "workingProfessional"}
                    onChange={(e) => {
                      setIsCorporate(false);
                      setTestimonialFor(e.target.value);
                    }}
                  />
                  <label className="professionbanner">Working</label>
                </div>
                <div className="col-lg-3 col-md-4">
                  <input
                    className="bannerinput"
                    type="radio"
                    name="testimonialFor"
                    value="Doctor"
                    checked={testimonialFor === "Doctor"}
                    onChange={(e) => {
                      setIsCorporate(false);
                      setTestimonialFor(e.target.value);
                    }}
                  />
                  <label className="professionbanner">Doctor</label>
                </div>
                <div className="col-lg-3 col-md-4">
                  <input
                    className="bannerinput"
                    type="radio"
                    name="testimonialFor"
                    value="Corporate"
                    checked={testimonialFor === "Corporate"}
                    onChange={(e) => {
                      console.log(e.target.value);

                      setIsCorporate(true);
                      setTestimonialFor(e.target.value);
                    }}
                  />
                  <label className="professionbanner">Corporate</label>
                </div>
                {!testimonialFor && (
                  <small style={{ color: "red", paddingLeft: "15px" }}>
                    Please select testimonial for
                  </small>
                )}
              </div>

              {isCorporate && (
                <div className="row mt-3">
                  <div className="col-lg-12">
                    <select
                      value={selectedCorporate}
                      onChange={(e) => setSelectedCorporate(e.target.value)}
                      style={{
                        height: "50px",
                        width: "100%",
                        padding: "10px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                        fontSize: "16px",
                        color: "#333",
                        outline: "none",
                      }}
                    >
                      <option value="">Select Corporate</option>
                      {corporates.map((corporate, index) => (
                        <option key={index} value={corporate._id}>
                          {corporate.corporateName}
                        </option>
                      ))}
                    </select>
                    {isCorporate && !selectedCorporate && (
                      <small style={{ color: "red" }}>
                        Please select a corporate
                      </small>
                    )}
                  </div>
                </div>
              )}
            </Form.Group>

            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                Update
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose1}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Testimonial;
