import { TbDeviceTabletPause, TbFileUpload } from "react-icons/tb";
import "./Experts.css";
import { Link, useNavigate } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Topbar from "../../components/topbar/Topbar";
import SideBar from "../../components/Sidebar/SideBar";
import { addShortsList } from "../redux/shorts";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../redux/common";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import helper from "../../helper/axiosHelper";
const Addexperts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState();
  const [imageFile, setImageFile] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isCorporate, setIsCorporate] = useState(false);
  const [corporates, setCorporates] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState("");
  const [shortsFor, setShortsFor] = useState([]);

  const addShortList = (data) => {
    let formData = new FormData();
    formData.append("videoUrl", data.videoUrl);
    formData.append("thumbnail", imageFile);
    formData.append("title", data.Title);
    formData.append("shortsFor", JSON.stringify(shortsFor));

    if (shortsFor.includes("Corporate")) {
      if (!selectedCorporate) {
        toast.error("Please select a corporate");
        return;
      }
      formData.append("corporate", selectedCorporate);
    }

    dispatch(loader(true));
    dispatch(
      addShortsList(formData, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          toast.success(resp.message);
          navigate("/list_to_experts");
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];
    setImageFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      //   setPreviewImage("");
    }
  };

  const handleOptionChange = (value) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(value)) {
        return prevSelectedOptions.filter((option) => option !== value);
      } else {
        return [...prevSelectedOptions, value];
      }
    });
    setIsCorporate(false);
  };

  useEffect(() => {
    const list_corporates = async () => {
      try {
        const response = await helper.postData(
          helper.baseUrl() + "getCorporates"
        );
        setCorporates(response?.data.corporates);
      } catch (error) {
        console.error("Error fetching corporates:", error);
      }
    };

    list_corporates();
  }, []);

  const {
    register: register,
    formState: { errors: errors },
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });
  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h3>Add Shorts</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/list_to_experts">Listen to Experts</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">Add Shorts</Link>
              </p>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="TittlE">
                <h4>Add Shorts</h4>
              </div>
              <div className="ExpertSBacKG">
                <form onSubmit={handleSubmit(addShortList)}>
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="ThumBnail blogcnt">
                        <div className="thumbnail-container adBlog">
                          <div className="blogadd">
                            {previewImage ? null : (
                              <label
                                htmlFor="file-upload"
                                className="file-upload-label"
                                style={{ paddingTop: "77px" }}
                              >
                                Image Dimensions (1700 x 1133)
                              </label>
                            )}
                            <input
                              id="file-upload"
                              type="file"
                              className="fileUpload"
                              onChange={handleProfilePic}
                              accept="image/*"
                            />
                            {previewImage && (
                              <div className="thumbnail-preview">
                                <img
                                  src={previewImage}
                                  alt="Thumbnail Preview"
                                  className="imgthumbnails"
                                />
                              </div>
                            )}
                          </div>
                          <div className="iconUpload">
                            <label
                              for="file-upload"
                              className="custom-file-upload"
                            >
                              <span className="text-center">
                                <TbFileUpload className="iconuploads blogicon uplIcon" />
                              </span>
                              <p className="uploadthumbs blogpad clkBlog">
                                Click here to upload
                              </p>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-9 pt-3">
                      <div className="row">
                        <div className="col-12">
                          <div className="SHortsForm">
                            <label>Title</label>
                            <br></br>
                            <input
                              type="text"
                              placeholder="Enter Title"
                              className="FormShorts"
                              name="Title"
                              {...register("Title", {
                                required: "Title is required",
                              })}
                            />
                            {errors.Title && (
                              <small style={{ color: "red" }}>
                                {errors.Title.message}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="SHortsForm">
                            <label>Video URL</label>
                            <br />
                            <input
                              type="text"
                              placeholder="https://www.youtube.com/embed/nmHzvQr3kYE"
                              className="FormShorts"
                              {...register("videoUrl", {
                                required: "Video URL is required",
                                pattern: {
                                  value:
                                    /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
                                  message: "Invalid URL format",
                                },
                              })}
                            />
                            {errors.videoUrl && (
                              <small style={{ color: "red" }}>
                                {errors.videoUrl.message}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="">
                            <label>Shorts For</label>
                            <div className="row bannerRadios">
                              <div className="col-lg-3 col-md-4">
                                <input
                                  className="bannerinput"
                                  type="checkbox"
                                  name="shortsFor"
                                  value="Student"
                                  checked={shortsFor.includes("Student")}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (shortsFor.includes("Corporate")) {
                                      toast.error(
                                        "Please unselect Corporate first"
                                      );
                                      return;
                                    }
                                    setShortsFor((prev) =>
                                      prev.includes(value)
                                        ? prev.filter((item) => item !== value)
                                        : [...prev, value]
                                    );
                                  }}
                                />
                                <label className="professionbanner">
                                  Student
                                </label>
                              </div>
                              <div className="col-lg-3 col-md-4">
                                <input
                                  className="bannerinput"
                                  type="checkbox"
                                  name="shortsFor"
                                  value="workingProfessional"
                                  checked={shortsFor.includes(
                                    "workingProfessional"
                                  )}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (shortsFor.includes("Corporate")) {
                                      toast.error(
                                        "Please unselect Corporate first"
                                      );
                                      return;
                                    }
                                    setShortsFor((prev) =>
                                      prev.includes(value)
                                        ? prev.filter((item) => item !== value)
                                        : [...prev, value]
                                    );
                                  }}
                                />
                                <label className="professionbanner">
                                  Working
                                </label>
                              </div>
                              <div className="col-lg-3 col-md-4">
                                <input
                                  className="bannerinput"
                                  type="checkbox"
                                  name="shortsFor"
                                  value="Doctor"
                                  checked={shortsFor.includes("Doctor")}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (shortsFor.includes("Corporate")) {
                                      toast.error(
                                        "Please unselect Corporate first"
                                      );
                                      return;
                                    }
                                    setShortsFor((prev) =>
                                      prev.includes(value)
                                        ? prev.filter((item) => item !== value)
                                        : [...prev, value]
                                    );
                                  }}
                                />
                                <label className="professionbanner">
                                  Doctor
                                </label>
                              </div>
                              <div className="col-lg-3 col-md-4">
                                <input
                                  className="bannerinput"
                                  type="checkbox"
                                  name="shortsFor"
                                  value="Corporate"
                                  checked={shortsFor.includes("Corporate")}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (
                                      shortsFor.length > 0 &&
                                      !shortsFor.includes("Corporate")
                                    ) {
                                      toast.error(
                                        "Please unselect other options first"
                                      );
                                      return;
                                    }
                                    setShortsFor(
                                      (prev) =>
                                        prev.includes(value)
                                          ? prev.filter(
                                              (item) => item !== value
                                            )
                                          : [value] // Only allow Corporate by itself
                                    );
                                    setIsCorporate(!isCorporate);
                                  }}
                                />
                                <label className="professionbanner">
                                  Corporate
                                </label>
                              </div>
                              {shortsFor.length === 0 && (
                                <small style={{ color: "red", float: "left" }}>
                                  Please select at least one option
                                </small>
                              )}
                            </div>

                            {isCorporate && (
                              <div className="row mt-3">
                                <div className="col-lg-12">
                                  <select
                                    value={selectedCorporate}
                                    onChange={(e) =>
                                      setSelectedCorporate(e.target.value)
                                    }
                                    style={{
                                      height: "50px",
                                      width: "100%",
                                      padding: "10px",
                                      borderRadius: "4px",
                                      border: "1px solid #ccc",
                                      fontSize: "16px",
                                      color: "#333",
                                      outline: "none",
                                    }}
                                  >
                                    <option value="">Select Corporate</option>
                                    {corporates.map((corporate, index) => (
                                      <option key={index} value={corporate._id}>
                                        {corporate.corporateName}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <div className="SHortsForm1">
                            <button className="Grn-Btn" type="submit">
                              add
                            </button>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="SHortsForm1">
                            <button
                              className="WHt-Btn"
                              type="button"
                              onClick={() => navigate("/list_to_experts")}
                            >
                              cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Addexperts;
